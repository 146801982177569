import {css} from 'lit-element';

export const globalStyles = css`
  *,
  *::after,
  *::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
`;
