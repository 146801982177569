import {html} from 'lit-html';
import {styleMap} from 'lit-html/directives/style-map.js';

import Button from '../../components/Button';
import Text from '../../components/Text';
import Image from '../../components/Image';

const TableElement = (el, userStyles) => {
  const {
    table: {align},
  } = userStyles;
  const parseItem = (item) => {
    const itemName = item.name.split('_');
    if (itemName[0] === 'Button') {
      if (itemName[1] === 'text') {
        const buttonUrl = itemName[2]
          ? `Button_url_${itemName[2]}`
          : 'Button_url';
        const link = el.find((item) => item.name === buttonUrl);
        return Button(item, link, userStyles.button) || '-';
      }
      if (itemName[1] === 'url') {
        return null;
      }
    } else if (itemName[0] === 'Image' && itemName[1] == 'url') {
      return Image(item, userStyles.image);
    } else {
      return Text('span', item.formattedValue || item.value);
    }
  };

  const inlineStyles = {
    textAlign: align,
  };

  return html`
    <tr>
      ${el.map((item) => {
        const res = parseItem(item);
        return res
          ? html`<td style=${styleMap(inlineStyles)}>${res}</td> `
          : null;
      })}
    </tr>
  `;
};

export default TableElement;
