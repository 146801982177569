import {html, LitElement, css} from 'lit-element';
import {styleMap} from 'lit-html/directives/style-map.js';

import './src/views/Cards/Cards';
import './src/views/List/List';
import './src/views/Table/Table';
import './src/views/Inline/Inline';

class EmbedTables extends LitElement {
  static get properties() {
    return {
      projectdata: {type: Object},
      userstyles: {type: Object},
      type: {type: String},
    };
  }

  constructor() {
    super();
    this.projectdata = {};
    this.userstyles = {
      showLabels: true,
      display: null,
      displayReversed: false,
      cards: {
        columns: 3,
      },
      singleElement: {
        direction: 'row',
        textAlign: 'right',
        justifyContent: 'space-between',
        boxShadow: {
          x: 0,
          y: 0,
          blur: 0,
          color: '0,0,0',
        },
        border: {
          top: 0,
          bottom: 2,
          left: 0,
          right: 0,
          color: '0,222,222',
        },
        borderRadius: {
          topLeft: 5,
          topRight: 5,
          bottomRight: 5,
          bottomLeft: 5,
        },
      },
      image: {
        borderRadius: {
          topLeft: 5,
          topRight: 5,
          bottomRight: 5,
          bottomLeft: 5,
        },
        boxShadow: {
          x: 0,
          y: 0,
          blur: 0,
          color: '124,100,200',
        },
      },
      button: {
        backgroundColor: '200,10,100',
        textColor: '245,245,245',
        borderRadius: {
          topLeft: 3,
          topRight: 3,
          bottomRight: 3,
          bottomLeft: 3,
        },
        isBold: true,
      },
      table: {
        align: 'left',
        header: {
          backgroundColor: '200,10,100',
          textColor: '245,245,245',
          isBold: true,
        },
      },
    };
    this.type = 'cards';
  }

  render() {
    let elementToRender = null;
    let projectData = this.projectdata;
    if (this.userstyles.displayReversed) {
      projectData = projectData.slice().reverse();
    }
    if (this.userstyles.display) {
      projectData = [...projectData.slice(0, this.userstyles.display)];
    }
    switch (this.type) {
      case 'cards':
        elementToRender = html`<cards-list
          projectdata=${JSON.stringify(projectData)}
          userstyles=${JSON.stringify(this.userstyles)}
        ></cards-list>`;
        break;
      case 'list':
        elementToRender = html` <list-view
          projectdata=${JSON.stringify(projectData)}
          userstyles=${JSON.stringify(this.userstyles)}
        ></list-view>`;
        break;
      case 'table':
        elementToRender = html`<table-view
          projectdata=${JSON.stringify(projectData)}
          userstyles=${JSON.stringify(this.userstyles)}
        ></table-view>`;
        break;
      case 'inline':
        elementToRender = html`<inline-view
          projectdata=${JSON.stringify(projectData)}
          userstyles=${JSON.stringify(this.userstyles)}
        ></inline-view>`;
        break;
      default:
        elementToRender = html`<cards-list
          projectdata=${JSON.stringify(this.projectdata)}
          userstyles=${JSON.stringify(this.userstyles)}
        ></cards-list>`;
        break;
    }
    return html`${elementToRender}`;
  }
}

window.customElements.define('embedtables-element', EmbedTables);
