import { html } from "lit-html";
import { styleMap } from "lit-html/directives/style-map.js";

const Image = (item, userStylesImage) => {
  const { borderRadius, boxShadow } = userStylesImage;
  const styles = {
    borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
    boxShadow: `${boxShadow.x}px ${boxShadow.y}px ${boxShadow.blur}px rgba(${boxShadow.color},0.2)`,
  };

  return html`
    <img style=${styleMap(styles)} width="100%" src=${item.value} />
  `;
};

export default Image;
