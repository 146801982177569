import {LitElement, html, css} from 'lit-element';

import {styleMap} from 'lit-html/directives/style-map';

import Button from '../../components/Button.js';
import Text from '../../components/Text.js';
import Image from '../../components/Image.js';

import {globalStyles} from '../../../globalStyles.js';

class InlineElement extends LitElement {
  static get properties() {
    return {
      item: {type: Object},
      userstyles: {type: Object},
    };
  }
  static get styles() {
    return [
      globalStyles,
      css`
        .row {
          display: inline;
        }
        .image {
          display: inline-block;
          height: 0;
          overflow: hidden;
          position: relative;
          vertical-align: bottom;
          width: 40px;
          height: 40px;
        }
        .image .imageInner {
          position: absolute;
          vertical-align: bottom;

          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .image img {
          vertical-align: bottom;
          height: 100%;
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .content span,
        .content p {
          word-wrap: break-word;
          margin-top: 0;
        }
      `,
    ];
  }
  constructor() {
    super();
    this.item = {};
    this.userstyles = {};
  }
  render() {
    const {showLabels} = this.userstyles;

    const image = this.item.find((item) => item.name === 'Image_url');
    const content = this.item.filter((item) => item.name !== 'Image_url');

    // const otherStyles = {
    //   borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
    //   boxShadow: `${boxShadow.x}px ${boxShadow.y}px ${boxShadow.blur}px rgba(${boxShadow.color},0.2)`,
    //   borderWidth: `${border.top}px ${border.right}px ${border.bottom}px ${border.left}px`,
    //   borderStyle: 'solid',
    //   borderColor: `rgb(${border.color})`,
    //   textAlign: textAlign,
    // };
    // const inlineStyles = Object.assign(layoutStyles, otherStyles);

    return html`
      <div class="row">
        ${image
          ? html`<div class="image">
              <div class="imageInner">
                ${Image(image, this.userstyles.image)}
              </div>
            </div>`
          : null}
        <span class="content">
          ${content.map((item) => {
            const itemName = item.name.split('_');
            if (itemName[0] === 'Button') {
              if (itemName[1] === 'text') {
                const buttonUrl = itemName[2]
                  ? `Button_url_${itemName[2]}`
                  : 'Button_url';
                const link = content.find((item) => item.name === buttonUrl);
                if (!link.value || !item.value) return null;
                return Button(item, link, this.userstyles.button);
              }
            }
            if (itemName[1] === 'url') {
              return null;
            }

            return html`
              <span class="contentItem">
                <span class="labelWrapper">
                  ${showLabels ? Text('span', `${item.name}: `) : null}
                </span>
                ${Text('span', item.formattedValue || item.value)}
              </span>
            `;

            // switch (item.name) {
            //   case 'Button_text':
            //     const link = content.find((item) => item.name === 'Button_url');
            //     return Button(item, link, this.userstyles.button);
            //     break;
            //   case 'Button_url':
            //     break;
            //   default:
            //     return html`
            //       <span class="contentItem">
            //         <span class="labelWrapper">
            //           ${showLabels ? Text('span', `${item.name}: `) : null}
            //         </span>
            //         ${Text('span', item.formattedValue || item.value)}
            //       </span>
            //     `;
            // }
          })}
        </span>
      </div>
    `;
  }
}
customElements.define('inline-element', InlineElement);
