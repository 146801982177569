import {html} from 'lit-html';
import {styleMap} from 'lit-html/directives/style-map.js';

const Button = (
  item,
  link,
  userStylesButton = {
    backgroundColor: '0,0,0',
    textColor: '200,200,200',
    isBold: true,
    borderRadius: {topLeft: 2, topRight: 2, bottomLeft: 2, bottomRight: 2},
  }
) => {
  const {backgroundColor, textColor, borderRadius, isBold} = userStylesButton;
  let styles = {
    padding: '10px',
    display: 'inline-block',
    textDecoration: 'none',
    margin: '3px',
    background: `rgb(${backgroundColor})`,
    color: `rgb(${textColor})`,
    textAlign: 'center',
    borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
    fontWeight: `${isBold ? '600' : '400'}`,
  };
  if (item.value && link.value)
    return html`<a
      style=${styleMap(styles)}
      href=${link.value}
      target="_blank"
      rel="noreferrer noopener"
      >${item.value}</a
    >`;
};
export default Button;
