import {LitElement, html, css} from 'lit-element';
import {styleMap} from 'lit-html/directives/style-map';

import './ListElement';

import {globalStyles} from '../../../globalStyles.js';

class List extends LitElement {
  static get properties() {
    return {
      projectdata: {type: Object},
      userstyles: {type: Object},
    };
  }
  constructor() {
    super();
    this.projectdata = {};
    this.userstyles = {};
  }
  static get styles() {
    return [
      globalStyles,
      css`
        .List {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .List ul {
          margin: 0;
          list-style: none;
          padding: 0;
        }
      `,
    ];
  }

  render() {
    return html` <div class="List">
      <ul>
        ${this.projectdata.map(
          (el) =>
            html`<list-element
              item=${JSON.stringify(el)}
              userstyles=${JSON.stringify(this.userstyles)}
            ></list-element>`
        )}
      </ul>
    </div>`;
  }
}
customElements.define('list-view', List);
