import {LitElement, html, css} from 'lit-element';
import {styleMap} from 'lit-html/directives/style-map';

import TableElement from './TableElement';

import {globalStyles} from '../../../globalStyles.js';

class Table extends LitElement {
  static get properties() {
    return {
      projectdata: {type: Object},
      userstyles: {type: Object},
    };
  }
  constructor() {
    super();
    this.projectdata = {};
    this.userstyles = {};
  }
  static get styles() {
    return [
      globalStyles,
      css`
        .Table {
          width: 100%;
        }
        .Table table {
          width: 100%;
          border-collapse: collapse;
        }
        /* Zebra striping */
        table tr:nth-of-type(even) {
          background: rgba(238, 238, 238, 0.556);
        }
        .Table td,
        .Table th {
          padding: 0.5rem 1rem;
          text-align: left;
          margin: auto;
        }
        .Table table th {
          background: #333;
          color: white;
          font-weight: bold;
        }

        .Table table td img,
        .Table table th img {
          height: 64px;
          width: 64px;
          object-fit: cover;
        }
        @media screen and (max-width: 920px) {
          .Table {
            overflow: auto;
            max-width: 100%;
            background: linear-gradient(
                to right,
                white 30%,
                rgba(255, 255, 255, 0)
              ),
              linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0
                100%,
              radial-gradient(
                farthest-side at 0% 50%,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0)
              ),
              radial-gradient(
                  farthest-side at 100% 50%,
                  rgba(0, 0, 0, 0.2),
                  rgba(0, 0, 0, 0)
                )
                0 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
            background-position: 0 0, 100%, 0 0, 100%;
            background-attachment: local, local, scroll, scroll;
          }
        }
      `,
    ];
  }

  render() {
    const {table, showLabels} = this.userstyles;

    const header = this.projectdata[0]
      .filter(({name}) => !name.match('Button_url*'))
      .map((el) =>
        el.name
          .replace('Button_text', 'Action')
          .replace('Image_url', 'Image')
          .replace('_', ' ')
      );

    const inlineStylesHeader = {
      background: `rgb(${table.header.backgroundColor})`,
      color: `rgb(${table.header.textColor})`,
      textAlign: table.align,
      fontWeight: table.header.isBold ? '600' : '400',
    };

    return html`<div class="Table">
      <table>
        ${showLabels
          ? html`<thead>
              <tr>
                ${header.map(
                  (el) =>
                    html`<th style=${styleMap(inlineStylesHeader)}>${el}</th>`
                )}
              </tr>
            </thead>`
          : null}

        <tbody>
          ${this.projectdata.map((el) => TableElement(el, this.userstyles))}
        </tbody>
      </table>
    </div>`;
  }
}
customElements.define('table-view', Table);
