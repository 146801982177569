// import { html } from "lit-html";
// import { styleMap } from "lit-html/directives/style-map.js";

// import CardsElement from "./CardsElement";

// import * as style from "./Cards.module.scss";

// const Cards = (data, userStyles) => {
//   const {
//     layout: { wrapper },
//   } = userStyles;
//   const inlineStyles = {
//     gridTemplateColumns: `repeat(${wrapper.columns}, 1fr)`,
//   };
//   return html`
//     <div class=${style.Cards} style=${styleMap(inlineStyles)}>
//       ${data.fields.map((el) => CardsElement(el, userStyles))}
//     </div>
//   `;
// };

// export default Cards;
import {LitElement, html, css} from 'lit-element';
import {styleMap} from 'lit-html/directives/style-map';

import {globalStyles} from '../../../globalStyles.js';

import './CardsElement';

class CardsList extends LitElement {
  static get properties() {
    return {
      projectdata: {type: Object},
      userstyles: {type: Object},
    };
  }
  constructor() {
    super();
    this.projectdata = {};
    this.userstyles = {};
  }
  static get styles() {
    return [
      globalStyles,
      css`
        .Cards {
          display: grid;
          justify-content: center;
          gap: 15px;
          width: 100%;
          position: relative;
        }
        @media (max-width: 920px) {
          .Cards {
            grid-template-columns: 1fr !important;
          }
        }
      `,
    ];
  }

  render() {
    const inlineStyles = {
      gridTemplateColumns: `repeat(${this.userstyles.cards.columns}, 1fr)`,
    };
    return html` <div class="Cards" style=${styleMap(inlineStyles)}>
      ${this.projectdata.map(
        (el) =>
          html`<cards-element
            item=${JSON.stringify(el)}
            userstyles=${JSON.stringify(this.userstyles)}
          ></cards-element>`
      )}
    </div>`;
  }
}
customElements.define('cards-list', CardsList);
