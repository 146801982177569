import {html} from 'lit-html';

const p = (text) => html`<p style="white-space: pre-line;">${text}</p>`;
const span = (text) => html`<span style="white-space: pre-line;"
  >${text}</span
>`;

const checkmark = () => html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <path
    fill="currentColor"
    d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
  />
</svg>`;
const cross = () => html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <path
    fill="currentColor"
    d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
  />
</svg>`;
const tags = {
  p,
  span,
};

const Text = (tag = 'span', item) => {
  console.log(item);
  if (item === 'TRUE') return checkmark();
  if (item === 'FALSE') return cross();
  return tags[tag](item);
};
export default Text;
