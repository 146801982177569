import {LitElement, html, css} from 'lit-element';

import {styleMap} from 'lit-html/directives/style-map';

import Button from '../../components/Button.js';
import Text from '../../components/Text.js';
import Image from '../../components/Image.js';

import {globalStyles} from '../../../globalStyles.js';

class ListElement extends LitElement {
  static get properties() {
    return {
      item: {type: Object},
      userstyles: {type: Object},
    };
  }
  static get styles() {
    return [
      globalStyles,
      css`
        .row {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-bottom: 1%;
          column-gap: 2%;
          align-items: center;
          width: 100%;
          padding: 1rem 2rem;
        }
        .row .image {
          margin-right: 2%;
          position: relative;
          flex: 1;
          align-self: flex-start;
          max-width: 10%;
        }
        .row .image::before {
          display: block;
          content: '';
          width: 100%;
          padding-top: 100%;
        }
        .image img {
          height: 100%;
          border-radius: inherit;
          object-fit: cover;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .content {
          flex: 5;
          min-width: 70%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        .content span,
        .content p {
          word-wrap: break-word;
          margin: 0;
        }
        .content span {
          margin-bottom: 5px;
        }
        .content .contentItem {
          margin: 0 2%;
        }
        .content .contentItem:first-child {
          font-size: 1rem;
          font-weight: 600;
        }
        .content .contentItem:not(.contentItem:first-child) {
          font-size: 0.9rem;
        }
        .content .labelWrapper span {
          font-size: 0.8rem;
          opacity: 0.8;
          font-weight: lighter;
          display: block;
        }
        .content .ctaButton {
          flex: 1;
          display: flex;
          justify-content: flex-end;
        }
      `,
    ];
  }
  constructor() {
    super();
    this.item = {};
    this.userstyles = {};
  }
  render() {
    const {
      singleElement: {
        direction,
        borderRadius,
        boxShadow,
        border,
        textAlign,
        justifyContent,
      },
      showLabels,
    } = this.userstyles;

    const content = this.item.filter((item) => !item.name.match('Image_url*'));

    const images = this.item.filter((item) => item.name.match('Image_url*'));
    const buttons = this.item.filter((item) => item.name.match('Button_text*'));
    // const link = content.find((button) => button.name === 'Button_url');

    const inlineStyles = {
      borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
      boxShadow: `${boxShadow.x}px ${boxShadow.y}px ${boxShadow.blur}px rgba(${boxShadow.color},0.2)`,
      borderWidth: `${border.top}px ${border.right}px ${border.bottom}px ${border.left}px`,
      borderStyle: 'solid',
      borderColor: `rgb(${border.color})`,
    };

    const contentInlineStyles = {
      textAlign: textAlign,
      justifyContent: justifyContent,
    };

    return html`
      <li>
        <div class="row" style=${styleMap(inlineStyles)}>
          ${images
            ? images.map((image) => {
                return html`
                  <div class="image">
                    ${Image(image, this.userstyles.image)}
                  </div>
                `;
              })
            : null}

          <div class="content" style=${styleMap(contentInlineStyles)}>
            ${content.map((item) => {
              const itemName = item.name.split('_');
              if (itemName[0] === 'Button') return null;
              return html`
                <div class="contentItem">
                  <div class="labelWrapper">
                    ${showLabels ? Text('span', item.name) : null}
                  </div>
                  ${Text('p', item.formattedValue || item.value)}
                </div>
              `;
            })}
          </div>
          <div class="ctaButton">
            ${buttons
              ? buttons.map((button) => {
                  const itemName = button.name.split('_');
                  const buttonUrl = itemName[2]
                    ? `Button_url_${itemName[2]}`
                    : 'Button_url';
                  const link = content.find((item) => item.name === buttonUrl);
                  if (!link.value || !button.value) return null;
                  return Button(button, link, this.userstyles.button);
                })
              : null}
          </div>
        </div>
      </li>
    `;
  }
}
customElements.define('list-element', ListElement);
