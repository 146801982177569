import {LitElement, html, css} from 'lit-element';

import {styleMap} from 'lit-html/directives/style-map';
import {globalStyles} from '../../../globalStyles.js';

import Button from '../../components/Button';
import Text from '../../components/Text';
import Image from '../../components/Image';

class CardsElement extends LitElement {
  static get properties() {
    return {
      item: {type: Object},
      userstyles: {type: Object},
    };
  }
  static get styles() {
    return [
      globalStyles,
      css`
        .row {
          display: grid;
          grid-template-columns: 1fr 2fr;
          position: relative;
          width: 100%;
          align-items: flex-start;
          justify-content: flex-start;
          font-family: inherit;
          height: 100%;
        }
        .image {
          height: 0;
          overflow: hidden;
          position: relative;
          padding-top: (1.6 / 1) * 100%;
        }
        .image .imageInner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .image img {
          height: 100%;
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .content {
          padding: 5%;
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
        }
        .content span,
        .content p {
          word-wrap: break-word;
          margin-top: 0;
        }
        .content .contentItem:first-child {
          font-size: 1.2rem;
          font-weight: 600;
        }
        .content .labelWrapper span {
          font-size: 0.8rem;
          opacity: 0.8;
          font-weight: lighter;
        }
      `,
    ];
  }
  constructor() {
    super();
    this.item = {};
    this.userstyles = {};
  }
  render() {
    const {
      singleElement: {
        direction,
        borderRadius,
        boxShadow,
        border,
        textAlign,
        justifyContent,
      },

      showLabels,
    } = this.userstyles;

    const image = this.item.find((item) => item.name === 'Image_url');
    const content = this.item.filter((item) => item.name !== 'Image_url');

    const rowLayout = {
      gridTemplateColumns: image ? '1fr 2fr' : 'auto',
      gridTemplateRows: '1fr',
    };
    const columnLayout = {
      gridTemplateRows: image ? 'auto auto' : 'auto',
      gridTemplateColumns: '1fr',
    };
    const layoutStyles = direction === 'row' ? rowLayout : columnLayout;

    const otherStyles = {
      borderRadius: `${borderRadius.topLeft}px ${borderRadius.topRight}px ${borderRadius.bottomRight}px ${borderRadius.bottomLeft}px`,
      boxShadow: `${boxShadow.x}px ${boxShadow.y}px ${boxShadow.blur}px rgba(${boxShadow.color},0.2)`,
      borderWidth: `${border.top}px ${border.right}px ${border.bottom}px ${border.left}px`,
      borderStyle: 'solid',
      borderColor: `rgb(${border.color})`,
      textAlign: textAlign,
    };
    const inlineStyles = Object.assign(layoutStyles, otherStyles);

    const inlineStylesImage = {
      paddingTop: `${direction === 'row' ? (1.6 / 1) * 100 : (9 / 16) * 100}%`,
    };

    const contentStyles = {
      justifyContent: justifyContent,
    };

    const buttonStyles = {
      margin: () => {
        switch (textAlign) {
          case 'left':
            return '0 auto 0 0';
          case 'center':
            return 'auto 0 0 0';
          case 'right':
            return '0  0 auto 0';
        }
      },
    };

    return html`
      <div class="row" style=${styleMap(inlineStyles)}>
        ${image
          ? html`<div class="image" style=${styleMap(inlineStylesImage)}>
              <div class="imageInner">
                ${Image(image, this.userstyles.image)}
              </div>
            </div>`
          : null}
        <div class="content" style=${styleMap(contentStyles)}>
          ${content.map((item) => {
            const itemName = item.name.split('_');
            if (itemName[0] === 'Button') {
              if (itemName[1] === 'text') {
                const buttonUrl = itemName[2]
                  ? `Button_url_${itemName[2]}`
                  : 'Button_url';
                const link = content.find((item) => item.name === buttonUrl);
                if (!link.value || !item.value) return null;
                return html`<div style=${styleMap(buttonStyles)}>
                  ${Button(item, link, this.userstyles.button)}
                </div>`;
              }
            }
            if (itemName[1] === 'url') {
              return null;
            }

            return html`
              <div class="contentItem">
                <div class="labelWrapper">
                  ${showLabels ? Text('span', item.name) : null}
                </div>
                ${Text('p', item.formattedValue || item.value)}
              </div>
            `;

            // switch (item.name) {
            //   case 'Button_text':
            //     const link = content.find((item) => item.name === 'Button_url');
            //     if (!link.value || !item.value) return null;
            //     return html`<div style=${styleMap(buttonStyles)}>
            //       ${Button(item, link, this.userstyles.button)}
            //     </div>`;
            //     break;
            //   case 'Button_url':
            //     break;
            //   default:
            //     return html`
            //       <div class="contentItem">
            //         <div class="labelWrapper">
            //           ${showLabels ? Text('span', item.name) : null}
            //         </div>
            //         ${Text('p', item.formattedValue || item.value)}
            //       </div>
            //     `;
            // }
          })}
        </div>
      </div>
    `;
  }
}
customElements.define('cards-element', CardsElement);
