import {LitElement, html, css} from 'lit-element';
import {styleMap} from 'lit-html/directives/style-map';

import './InlineElement';

import {globalStyles} from '../../../globalStyles.js';

class Inline extends LitElement {
  static get properties() {
    return {
      projectdata: {type: Object},
      userstyles: {type: Object},
    };
  }
  constructor() {
    super();
    this.projectdata = {};
    this.userstyles = {};
  }
  static get styles() {
    return [globalStyles];
  }

  render() {
    return html` <span class="Inline">
      ${this.projectdata.map(
        (el) =>
          html`<inline-element
              item=${JSON.stringify(el)}
              userstyles=${JSON.stringify(this.userstyles)}
            ></inline-element>
            ${this.projectdata.length > 1 ? ',' : null}`
      )}
    </span>`;
  }
}
customElements.define('inline-view', Inline);
